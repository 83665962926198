import { flatten } from 'flat'
import Cookies from 'js-cookie'

import i18n from '@shared/i18n/vue-i18n'
import { sanctumCsrfCookie, sanctumCsrfCookieUrl } from '@shared/http/api'

export function handleResponseSuccess(response, options) {
  const { store } = options

  const currentUrl = window.location.hostname

  if (
    currentUrl.includes(import.meta.env.VITE_APP_SUBDOMAIN)
    || currentUrl.includes(import.meta.env.VITE_PRO_SUBDOMAIN)
  ) {
    // Server refresh session expiration at each request
    store.commit('auth/REFRESH_SESSION_EXPIRATION')
  }

  return response
}

export function handleResponseFailure(error, options) {
  const { store } = options

  if (alreadyLogged(error)) {
    refreshUser(options)
  }

  if (isSessionExpired(error, options)) {
    // Display re-authentication modal
    store.commit('auth/SET_REAUTHENTICATION_NEEDED', true)
  }

  generateErrorMessage(error, options)

  return Promise.reject(error)
}

export async function handleRequestSuccess(config) {
  // If original request is not to fetch the csrf cookie
  // and if the csrf cookie is missing (assumed to be expired)
  if (
    sanctumCsrfCookieUrl !== config.url
    && !Cookies.get(config.xsrfCookieName)
  ) {
    // Refresh the csrf cookie
    await sanctumCsrfCookie()
  }

  // Convert object data to FormData
  if (config.data) {
    if (config.method === 'patch') {
      config.data._method = config.method.toUpperCase() // specify the method in the request's body
      config.method = 'post' // Laravel API only accepts POST method when Content-Type is multipart/formdata
    }
    config.data = objectToFormdata(config.data)
  }

  return config
}

export function handleRequestFailure(error) {
  return Promise.reject(error)
}

// Convert a JS object to a FormData object
function objectToFormdata(object) {
  const data = new FormData()

  Object.entries(flatten(object)).forEach(([fieldName, fieldValue]) => {
    fieldName = fieldName.split('.').map((fieldNamePart, index) => {
      // Convert JSON key to valid formdata key,
      // e.g.: 'first.second.third' => 'first[second][third]'
      if (index !== 0) {
        return `[${fieldNamePart}]`
      }

      return fieldNamePart
    })
      .join('')

    // Since value will be converted to string,
    // the API will interpret "1" and "0" with their correct bool value
    if ([true, false].includes(fieldValue)) {
      fieldValue = fieldValue ? 1 : 0
    } else if (fieldValue === null) {
      // A null value must be sent as an empty string
      fieldValue = ''
    } else if (typeof fieldValue === 'undefined') {
      // Undefined value must not be sent e.g.: an empty file input
      return
    }

    data.append(fieldName, fieldValue)
  })

  return data
}

// Check if the error tells that the user is already logged in
function alreadyLogged(error) {
  return (
    error?.response?.status === 409 // conflict HTTP error
    && error?.response?.data?.already_logged
  )
}

// Display a user-friendly message based on the HTTP error
function generateErrorMessage(error, options) {
  const { store } = options

  // ignore failed requests in offline mode
  if (store.state.online && !store.state.online.active) {
    return
  }

  let errorToDisplay = 'default'
  let errorMessage = null

  // Pick error message directly from the error's response
  if (error?.response?.data?.danger) {
    errorMessage = error?.response?.data?.danger
  } else if (error?.response) {
    // 422 : let the form handle the errors display
    // 401 : let the user be redirect to login form
    // 404 : let app handle what is rendered to the user
    // Credential not verified : let app handle what is rendered to the user
    if (
      [401, 422, 404].includes(error.response.status)
      || credentialNotVerified(error, options)
    ) {
      return
    }

    if (i18n.global.te(`exceptions.${error.response.status}`)) {
      errorToDisplay = error.response.status
    }
  }

  if (!errorMessage) {
    errorMessage = i18n.global.t(`exceptions.${errorToDisplay}`)
  }

  store.commit('flashes/ADD_FLASH', { message: errorMessage, type: 'danger' })
}

function refreshUser(options) {
  const { store, router } = options
  // Refresh user
  store.commit('loading/ENABLE')
  store.dispatch('auth/getAuthenticatedUser')
    .then(async () => {
      await router.push('/')
    })
    .finally(() => {
      store.commit('loading/DISABLE')
    })
}

// Authenticated client side, but not server-side
function isSessionExpired(error, options) {
  const { store } = options
  return store.state.auth.authenticated && error?.response?.status === 401
}

// Check if error indicates that user's credential is not verified
function credentialNotVerified(error, options) {
  const { store } = options

  return (
    error?.response?.status === 403
    && !store.state.auth.user?.attributes?.has_verified_credential
  )
}
